 
  .scrollBar2 {
    position: fixed;
    /* paddi-top: 30%; */
    top:0;
      /* right: 1vw; */
    left: 48vw;
    height: 50%;
    z-index: 101;
    width: 15px;
    background: linear-gradient(to bottom, #0f4b9e, #12A84B);
    transition: height 200ms ;
    /* text-align: center; */
    color: #fff;
    display: flex;
    /* align-items: center; */
    }


    .element-to-rotate {
        display: block;
        transform-origin: top left;
        /* Note: for a CLOCKWISE rotation, use the commented-out
           transform instead of this one. */
        /* transform: rotate(-90deg) translate(-100%); */
        transform: rotate(90deg) translate(0, -100%);
        margin-top: -50%;
        /* Not vital, but possibly a good idea if the element you're rotating contains
           text and you want a single long vertical line of text and the pre-rotation
           width of your element is small enough that the text wraps: */
        white-space: nowrap;
      }
      
    .scrollBar3{
        position: fixed;
        background: rgba(255, 255, 255, 0.14);
        top:0;
          /* right: 1vw; */
        left: 48vw;
        height: 50%;
        z-index: 101;
        width: 15px;
        /* background: linear-gradient(to bottom, #0f4b9e); */
         /* text-align: center; */
        color: #fff;
        display: flex;
        /* align-items: center; */
        }
   
    .scrollBar {
        position: fixed;
        /* paddi-top: 30%; */
        top:0;
          /* right: 1vw; */
        left: 48vw;
        height: 0%;
        z-index: 101;
        width: 15px;
        background: linear-gradient(to bottom, #0f4b9e, #12A84B);
        transition: 50px 200ms ;
        /* text-align: center; */
        color: #fff;
        display: flex;
        /* align-items: center; */
        }