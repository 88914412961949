.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  border-bottom: 3px solid #12A84B;
  
}

.rwt__tab[aria-selected="true"] {
  position: relative;
  font-size: 20px;
  color:#0f4b9e;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer;
  font-size: 20px;

}

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 4px solid #12A84B;
}

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: 20px;
  padding: 1rem 2rem;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.player-wrapper {
  position: relative;
  padding-top: 80.25%; /* 720 / 1280 = 0.5625 */
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.active{
  border-bottom:2px solid #12A84B;
  color:black;
}

body {
  background: #F8FDFD;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.textFont {
  font-family: 'Poppins', sans-serif;
}

.benefitstext{
  color:#626687;
}
p, a{
     font-family: 'Poppins', sans-serif;  
     font-size: 16px;
    margin-bottom: .3em;
}

.ant-collapse-header{
  font-family: 'Poppins', sans-serif;  
  font-size: 16px;
  font-weight: bold;
}

.anticon-up, .ant-collapse-arrow{
   font-size: 16px;
  font-weight: bold;
}

.learn{
  padding-left: 5px;
  /* padding-right: ; */
  padding-bottom: 30px;  
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 24px;
  overflow: hidden;
  background: #f7f7f7;
  border: 0px;
  border-radius: 2px;
}


.navFont {
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
}

.headFont {
  font-family: 'Rubik', sans-serif;
}

.textBold{
  font-weight: bold;
}

.justify {
  text-align: justify;
  text-justify: inter-word;
}

.indexedStep, .indexedStep3, .indexedStep4, .indexedStep5 {
  color: white;
  width: 20px;
  height: 20px;
  font-size: 12px;
  background-color: rgba(211, 211, 211, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
 }
 
.downloadbtn{
  margin-left: -3% !important;
}

.indexedStep.accomplished{
  background: linear-gradient(to right, #0f4b9e, #0f4b9e);
  /* box-shadow: -3px -4px 58px 18px rgba(64,185,209,0.63); */
}

.indexedStep3.accomplished {
  background: linear-gradient(to right, #12A84B, #0f4b9e);
  /* box-shadow: -3px -4px 58px 18px rgba(64,185,209,0.63); */
}
 
.indexedStep4.accomplished {
  background: linear-gradient(to right, #12A84B, #12A84B);
  /* box-shadow: -3px -4px 58px 18px rgba(64,185,209,0.63); */
}
.indexedStep5.accomplished {
  background: linear-gradient(to right, #12A84B, #12A84B);
  /* box-shadow: -3px -4px 58px 18px rgba(64,185,209,0.63); */
}

.transitionStep {
  filter: grayscale(1);
}

.transitionStep.accomplished {
  filter: grayscale(0);
}
.scrollbar-primary {
  scrollbar-color: #4285f4 #f5f5f5;
}
.scrollbar-primary::-webkit-scrollbar {
width: 12px;
background-color: #F5F5F5; }




.scrollbar-primary::-webkit-scrollbar-thumb {
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
background-color: #4285F4; }

footer.page-footer {
  background-color: #3b295a;
  margin-top: 1rem;
}
.navbar .btn-group .dropdown-menu a:hover {
  color: #000 !important;
}
.navbar .btn-group .dropdown-menu a:active {
  color: #fff !important;
}
 


.card4 {
  background: #FFFFFF;
    box-shadow: 0px 13px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
  display: block;
  position: relative;
  color:#ffffff;
  max-width: 262px;
  background-color: #FFFFFF;
   padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
.card4:hover .card-body h1, .card4:hover .card-body h6{
  color:#ffffff;
}
  .card4:before {
    color: #ffffff;
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: linear-gradient(to right top, #12a84b, #009981, #006bb6, #0f4b9e);
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
    color: #ffffff;
  }

  .card4:hover:before {
    color: #ffffff;
    transform: scale(21);
  }

  .card4:hover:after {
    color: #ffffff;
   }
  
 

.hoverEffectOnText:hover {
     color: #ffffff;
}
 

.circle {
  border-radius: 50%;
}

.divided {
  display: flex;
  align-items: center;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 5px
}

.card1 {
  /* position: absolute; */
  width: 350.76px;
  /* height: 450.28px; */
  /* left: 1099px;
  top: 1124px; */
  
  background: #FFFFFF;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 10px
}

.card2 {
  /* position: absolute; */
  width: 60%;
  height: 450.28px;
  /* left: 1099px;
  top: 1124px; */
   
  /* padding-top:10%; */
  background: #FFFFFF;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 5px
}


.container1{
  width: 95%;
}

.container3{
  width: 100%;
  padding-right: 2px;
  padding-left: 2px;
  margin-right: auto;
  margin-left: auto;
}

.navbar {
  font-weight: 300;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.1);
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.alignCenter{
  display: flex;
	justify-content: center;
	align-items: center;
}

.alignRight1{
  /* display: flex; */
	justify-content: right;
	align-items: right;
}

.alignCenter1{
	justify-content: center;
	align-items: center;
}

.alignCenter2{
	/* justify-content: center; */
	align-items: center;
}

.ptop{
  padding-top:50px;
}

.pleft20{
  padding-left:50px;
}

.pright20{
  padding-right:20px;
}


.homebtn{
  background:  linear-gradient(to right, #0F4B9E 75%, #12A84B  25% );
    font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  border:0
}

.homebtn:hover{
  background:  linear-gradient(to right, #0F4B9E 75%, #12A84B  25% );
  box-shadow: 8px 8px #99bdff;
  transition: .3s;
}
 

.btn.hover-slide-left::before {
  top: 0; bottom: 0; right: 0; 
  height: 100%; width: 0%;
}
.btn.hover-slide-left:hover::before {
  width: 100%;
}
.contactbtn{
  background:  linear-gradient(to right, #0F4B9E 85%, #12A84B  15% );
    font-family: 'Poppins', sans-serif;
  border-radius: 5px;
  border:0
}

.contactbtn:hover{
  background:  linear-gradient(to right, #0F4B9E 85%, #12A84B  25% );
  box-shadow: 8px 8px #99bdff;
  transition: .3s;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}
.arrow-btn {
  top: 100%;
  z-index: 1;
}
.next {
  float: right;
}

 .slick-dots li button {
  font-size: 50;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.center1 {
  /* padding-top: 5%; */
    padding-bottom: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.setRight {
     padding-bottom: 3%;
  display: flex;
  justify-content: right;
  align-items: right;
}

.faq{
  box-shadow: 2px 10px 28px rgba(75, 0, 129, 0.12);
  border-radius: 10px;
}

.largePaddingLeft{
  /* padding-top: 20%; */
  margin-top: 50%;
}
  
.orientationcss {
  transform: rotate(90deg);
}



@media only screen and (max-width: 800px) {
  .paddingTop {
    padding-top: 20px;
  }

  .alignToleft{
    text-align: left;
  }
  .hide{
    display: none;
  }

  .btnleftMargin{
    margin-left: -2% !important;
  }
  .logoFooter{
    margin-left: -12px;
  }
  .rwt__tab{
     /* display: flex;
    flex-direction: row;  */
    text-align: center;
    align-items: center;
    font-size: 15px
  }
 
  .tabSettings{
    font-size: 15px

  }

.element-to-rotate {
  display: block;
  transform-origin: top left;
 
   transform: rotate(90deg) translate(0, -100%);
  margin-top: -50%;
  white-space: nowrap;
}


  .RSPBprogressBar{
    margin-left:60%;
    width: 420%;
    height: 10px;
    line-height: 1;
    border-radius: 10px;
    position: relative;
    background-color: rgba(211, 211, 211, 0.6);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 0;
  }
     


  .textToCenter{
    justify-content: center;
    align-items: center;  
    text-align: center!important;
  }

  .textToleft{
    justify-content: left;
    align-items: left;  
  }
  .mPad{
    padding-top: 40px!important;
  }
  .mpadper{
    padding-top: 20% !important;
  }
  

  .marginpadper{
    margin-top: 18% !important;
  }

  .leftPad{
    padding-left:15px;
  }
  .leftPad10{
    padding-left:10px;
  }

  .leftPadding10{
    margin-left:-2%;
  }

  .leftPadding15{
    margin-left:-15%;
  }

  .leftPadding5{
    margin-left:-6%;
  }

  .leftPaddingSol{
    margin-left:-9%;
  }


  .leftPadBtn{
    margin-left:20%;
  }

  .leftPad5{
    padding-left:5px;
  }

  .leftMarginSmall{
    margin-left:10px;
  }

  .topMarginSmall{
    margin-top:20%;
  }

  .leftPad35{
    padding-left:35px;
  }

  .leftPad5Per{
    padding-left:5%;
  }


  .bottomPad{
    padding-bottom: 25px;
  }

  .topPad{
    padding-top: 25px;
  }

  .topPad10{
    padding-top: 10px;
  }

  .leftmarginIcon{
    margin-left: 10% !important;
  }
  .leftmarginIcon11{
    margin-left: 14% !important;
  }


  .topPad35{
    padding-top: 35px !important;
  }

  .smallWidth{
    width: 105%;
    padding-left: -10px;
    margin-left: auto;
    margin-right: auto;

  }

  .leftPadBtn{
    margin-left: -10px;
  }

  .card-body1 {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 0rem;
}

.sectionBgSmall{
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-image: url(./images/svg/line1.svg) !important;
}

.mpadper55{
  padding-top: 5% !important;
}

.aboutbtn{
  margin-left: -8% !important;
}
.imageSol{
  width: 100% !important;
}
}

@media only screen and (min-width: 600px) {
  .hide1{
    display: none;
  }

  .btnLeftFooter{
    margin-left: -4% !important;
  }
 
  .imageSol{
    width: 95% !important;
  }
  .RSPBprogressBar{
  height: 10px;
  line-height: 1;
  border-radius: 10px;
  position: relative;
  background-color: rgba(211, 211, 211, 0.6);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.benefitsRightMargin23333{
  margin-left: 4% !important;
}
 
.buttonLeft {
  /* display: block; */
  margin-left: -10%;
}
.mpadper{
  padding-top: 22%;
}
.mpadper1{
  padding-top: 11%;

}

.mpadper20{
  padding-top: 20% !important;
}
.mpadper30{
  padding-top: 30% !important;
}

.buttonLeft5 {
  /* display: block; */
  margin-left: -3.5%;
}

.buttonLeft55 {
  /* display: block; */
  margin-left: -8%;
}

  .textToCenter{
    justify-content: center;
    align-items: center;  
  }

  .sectionBg{
    background-repeat: no-repeat;
    background-position-y: 60%;
    background-position-x: center;
  }


  .card-body1 {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  .mainTittle{
    font-size: 45px;
  }

  .contactForm{
    width: 100%;
    /* height: 450.28px; */
  }
  .topPadding{
    padding-top: 20%;
  }
  .topPadding30{
    padding-top: 30px;
  }

  .leftMargin{
    margin-left: 5%;
  }

  .leftMargin211{
    margin-left: -5% !important;
  }



  .paddingTopSet{
    padding-top: -50px;
  }
  .container4 {
    width: 75%;
    padding-right: 15px; 
     padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}
.tabsWidth{
  width: 50% !important;
}

}

@media only screen and (min-width: 1100px) {
  .metric{
    padding-left: 5% !important;  
    margin-left: 5% !important;
  }
}

.marginCardsBenefits{
  margin-top: -23% !important;
  /* padding-left: 5% !important;
  padding-right: 10% !important; */
}

.marginCardsBenefits3{
  margin-top: -5% !important;
  /* padding-left: 5% !important;
  padding-right: 10% !important; */
}

.marginCardsBenefits2{
  margin-top: -23% !important;
  /* padding-left: 5% !important;
  padding-right: 10% !important; */
}

.marginCardsBenefitsSmall{
  margin-top: -20% !important;
  /* padding-left: 10% !important;
  padding-right: 20% !important; */
}

.marginCardsBenefitsSmall123{
  margin-top: -10% !important;
  /* padding-left: 10% !important;
  padding-right: 20% !important; */
}
.benefitPadding{
  margin-right: -5.1% !important;
}

.benefitsRightMargin{
  margin-right: -5.2% !important;
}
.benefitsRightMargin1{
  margin-right: -3.25% !important;
}

.benefitsRightMargin23{
  margin-right: -4% !important;
}




.marginCardsBenefits1{
  margin-top: -18% !important;
  /* padding-left: 10% !important;
  padding-right: 20% !important; */
}

.mPad1{
  padding-top: 40px;
}

 

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255,255,255,0.05);
  width: 100%;
  top: 0;
  left: 0;
}



#progressBar {
  background: linear-gradient(to left, red, violet);
  transform-origin: top left;
  transform: scale(0,0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 10px;
}

.card3 {
  background: #FFFFFF;
  box-shadow: 5px 20px 50px rgba(74, 153, 211, 0.2);
  border-radius: 5px
}

.setWidth{
  min-width: 100%;
}


.card {
  background: #FFFFFF;
  box-shadow: 0 0px 0px 0 rgb(0 0 0 / 0%), 0 0px 0px 0 rgb(0 0 0 / 0%);
  border-radius: 10px
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}