@charset 'UTF-8';
.slick-dots,.slick-next,.slick-prev{
	position:absolute;
	display:block;
	padding:0
}
.slick-dots li button:before,.slick-next:before,.slick-prev:before{
	font-family:slick;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale
}

 
.slick-next,.slick-prev{
	font-size:20px;
	line-height:0;
	top:50%;
	width:20px;
	height:20px;
	-webkit-transform:translate(0,-50%);
	-ms-transform:translate(0,-50%);
	transform:translate(0,-50%);
	cursor:pointer;
	color:transparent;
	border:none;
	outline:0;
	background:0 0
}
.slick-next:focus,.slick-next:hover,.slick-prev:focus,.slick-prev:hover{
	color:transparent;
	outline:0;
	background:0 0
}
.slick-next:focus:before,.slick-next:hover:before,.slick-prev:focus:before,.slick-prev:hover:before{
	opacity:1
}
.slick-next.slick-disabled:before,.slick-prev.slick-disabled:before{
	opacity:.25
}
.slick-next:before,.slick-prev:before{
	font-size:20px;
	line-height:1;
	opacity:.75;
	color:#000
}
.slick-prev{
	left:-25px
}
[dir=rtl] .slick-prev{
	right:-25px;
	left:auto
}
.slick-prev:before{
	content:'←'
}
.slick-next:before,[dir=rtl] .slick-prev:before{
	content:'→'
}
.slick-next{
	right:-25px
}
[dir=rtl] .slick-next{
	right:auto;
	left:-25px
}
[dir=rtl] .slick-next:before{
	content:'←'
}
.slick-dotted.slick-slider{
	margin-bottom:5px
}
.slick-dots{
	bottom:-50px;
 	width:100%;
	margin:0;
	list-style:none;
	text-align:center
}
.slick-dots li{
	position:relative;
	display:inline-block;
	width:20px;
	height:20px;
	margin:0 5px;
	padding:0;
	cursor:pointer
}
.slick-dots li button{
	font-size:50px;
	line-height:0;
	display:block;
	width:20px;
	height:20px;
	padding:5px;
	cursor:pointer;
	color:transparent;
	border:0;
	outline:0;
	background:0 0
}
.slick-dots li button:focus,.slick-dots li button:hover{
	outline:0
}
.slick-dots li button:focus:before,.slick-dots li button:hover:before{
	opacity:1
}
 

.slick-dots li button:before {
    font-family: 'slick';
    font-size: 16px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #6562CE;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #6562CE;
}
/*# sourceMappingURL=slick-theme.min.css.map */
